var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ml-stack-nav__link-content" }, [
    _c("span", {
      staticClass: "ml-stack-nav__link-text",
      domProps: { textContent: _vm._s(_vm.item.content.linkText) }
    }),
    _vm._v(" "),
    _vm.item.content.linkLang
      ? _c("span", {
          staticClass: "ml-stack-navlink-text-a11y",
          attrs: { lang: _vm.item.content.linkLang },
          domProps: { textContent: _vm._s(_vm.item.content.linkTextA11y) }
        })
      : _vm.hasSublist()
      ? _c(
          "span",
          {
            staticClass: "ml-stack-nav__link-subhint",
            class: { "is-hidden": _vm.scount === 0 }
          },
          [
            _c("span", {
              staticClass: "ml-stack-nav__link-subhint-text",
              domProps: { textContent: _vm._s(_vm.scount) }
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "is-aural visually-hidden",
              domProps: {
                textContent: _vm._s(_vm.$store.state.data.lngtxt.subListInfo)
              }
            })
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }