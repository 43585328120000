var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "main-navigation__list-link is-burger",
      class: _vm.isActive(),
      attrs: { type: "button", "data-js-item": "js-skip-links-navvuebutton" },
      on: {
        click: function($event) {
          return _vm.toggleStackNav()
        }
      }
    },
    [
      _c("span", { staticClass: "main-navigation__list-link-text" }, [
        _vm._v(_vm._s(_vm.opencloseNavText()))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }