<template>
  <li
    class="ml-stack-nav__item"
    :class="[
      setActiveClass(),
      setOpenClass()
    ]"
  >
    <NavLink v-if="item" :item="item" :scount="subListCount" />
    <NavSubList v-if="item.content.sublist && $store.state.isRenderSublist" :item="item" />
    <NavLoading v-if="$store.state.uiUpdateLoading" />
  </li>
</template>

<script>
import NavLink from "./NavLink.vue";
import NavSubList from "./NavSubList.vue";
import NavLoading from "./NavLoading.vue";

export default {
  name: "NavListItem",
  props: ["item", "index"],
  components: {
    NavLink,
    NavSubList,
    NavLoading
  },
  computed: {
    subListCount() {
      if (this.item.content.sublist) {
        return this.item.content.sublist.content.items.length;
      }

      return 0;
    },
    subListClass() {
      if (this.item.settings) {
        return this.item.settings.sublistClasses;
      }
      return "";
    },
  },
  methods: { 
    setOpenClass() {
      return this.item.content.link.settings.isOpen ? 'is-open' : '';

    },
    setActiveClass() {
      return this.item.content.link.settings.isActive ? 'is-active' : '';

    }
  },
};
</script>
