<template>
  <a
    :href="getHref()"
    v-bind="getTarget()"
    v-on:click="openNextLevel"
    class="ml-stack-nav__link"
    :class="item.content.link.settings.linkClasses"
  >
    <NavLinkContent :item="item.content.link" :scount="scount" />
  </a>
</template>

<script>
import NavLinkContent from "./NavLinkContent.vue";

export default {
  name: "NavLink",
  props: ["item", "scount"],
  components: {
    NavLinkContent,
  },
  created: function () {
    if (this.item.content.link.settings.linkClasses === "is-active") {
      this.$store.dispatch(
        "activeItem",
        this.item.parent.isRoot === true ? null : this.item.parent
      );
    }
  },
  methods: {
    openNextLevel() {
      if (this.item.content.link) {
        this.item.content.link.settings.aria = true;
        this.item.content.link.settings.isActive = true;
        this.item.content.link.settings.isOpen = true;

        if (this.item.parent.isRoot) {
          this.$store.dispatch("activate");
        } else {
          this.item.parent.content.link.settings.isActive = false;
        }

        this.$store.dispatch("navWrapperLevelDown");
        this.$store.dispatch("activeItem", this.item);
        if (!this.$store.state.isRenderSublist && this.scount > 1) {
          this.$store.dispatch("isRenderSublist", true);
          this.$store.dispatch("uiUpdateLoading", true);
        }
      }
    },
    getHref() {
      return this.scount > 1 ? "#" : this.item.content.link.content.linkHref;
    },
    // target="_blank" rel='noopener'
    getTarget() {
      if (this.item.content.link) {
        const linkTarget = this.item.content.link.content.linkTarget;
        return linkTarget
          ? {
              target: linkTarget,
              rel: "noopener",
            }
          : null;
      }
    },
  },
};
</script>
