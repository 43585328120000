var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "ml-stack-nav__item",
      class: [_vm.setActiveClass(), _vm.setOpenClass()]
    },
    [
      _vm.item
        ? _c("NavLink", { attrs: { item: _vm.item, scount: _vm.subListCount } })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.content.sublist && _vm.$store.state.isRenderSublist
        ? _c("NavSubList", { attrs: { item: _vm.item } })
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.uiUpdateLoading ? _c("NavLoading") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }