import App from "./src/App.vue";
import store from './src/store';

(function(window) {
	let _init = ({ config = {}, container = '' }) => {
		const el = document.getElementById(container);
		if (el) {
			console.log('LMUcast Initialised');
			new Vue({				
				store: store(config),
				render : h => h(App),
				data: config
			}).$mount(el);
		} else {
			console.error('LMUcast Initialised failed!');
		}
	};

	window.LMUcast = {
		init: _init
	};
})(window);
