var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { staticClass: "fragment-li" }, [
    _c(
      "ul",
      { staticClass: "fragment-ul" },
      [
        _vm.item.content.headline
          ? _c(
              "h4",
              {
                staticClass: "ml-stack-nav__sub-headline",
                class: _vm.item.settings.navigationClasses
              },
              [_vm._v(_vm._s(_vm.item.content.headline))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.item.content.items, function(item, index) {
          return _c("NavListItem", { key: index, attrs: { item: item } })
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }