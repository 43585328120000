<template>
    <nav class="ml-stack-nav" :class="[isOpen(), isActive()]">
      <h3 v-if="$store.state.data" class="ml-stack-nav__headline" v-text="getHeadline()"></h3>
      <button class="ml-stack-nav__close"  v-on:click="closeNav()" v-text="closeButtonText()"></button>
      <NavList v-if="$store.state.data" :list="$store.state.data.navigationLists" />
	  <NavLoading  v-if="!$store.state.data && !$store.state.fallbackError" />
      <LogError v-if="!$store.state.data && $store.state.fallbackError" />
  </nav>
</template>

<script>
import NavList from "./components/NavList.vue";
import NavLngSwitch from "./components/NavLngSwitch.vue";
import NavLoading from "./components/NavLoading.vue";
import LogError from "./components/LogError.vue";

export default {
	name: "StacknavigationApp",
	components: {
		NavList,
		NavLngSwitch,
		NavLoading,
		LogError
	},
	created: async function() {
		await this.$store.dispatch("getNavData");
	},

	methods: {
		ariaExpanded() {
			return this.$store.state.isOpen ? "true" : "false";
		},
		isActive() {
			return this.$store.state.isActive ? "is-active" : "";
		},
		isOpen() {
			return this.$store.state.isOpen ?  "is-open" : "";
		},
		closeNav() {
			this.$store.dispatch("toggleStackNav");
			this.$store.dispatch("open");

			if (this.$store.state.isActive && this.$store.state.activeItem === null) {
				this.$store.dispatch("activate");
			}

		},
		getHeadline() {
			return this.$store.state.data == null
				? ""
				: this.$store.state.data.lngtxt.headline;
		},
		closeButtonText() {
			return this.$store.state.data == null
				? ""
				: this.$store.state.data.lngtxt.closeBtnText;
		},

	}
};
</script>
