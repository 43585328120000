
function addBodyScrolable(state) {
	const el = document.body;
	const className = 'isnt-scrollable';
	if (state.isToggleStackNav) {
		el.classList.add(className);
	} else {
		el.classList.remove(className)
	}
}

const mutations = {
    open(state) {
        state.isOpen = !state.isOpen;
        addBodyScrolable(state);
    },
    activate(state) {
        state.isActive = !state.isActive;
    },
    mobileBack(state) {
        const item = state.activeItem;
        item.content.link.settings.aria = false;
        item.content.link.settings.isOpen = false;
        item.content.link.settings.isActive = false;

        if (item.parent.isRoot) {
            state.isActive = !state.isActive;
        } else {
            item.parent.content.link.settings.isActive = true;
        }

    },
    setActiveItem(state, item) {
        state.activeItem = item;
    },
    setExpand(state) {
        state.isToggleStackNav = !state.isToggleStackNav;
    },
    setIsLevelDown(state) {
        state.isLevelDown = !state.isLevelDown;
    },
    setNavData(state, model) {
        state.data = model;
    },
    setTabHidden(state) {
        state.isTabHidden = !state.isTabHidden;
    },
    logError(state, fallbackError) {
        state.fallbackError = fallbackError;
    },
    setRenderSublist(state, model) {
        state.isRenderSublist = model;
    },
    setUiUpdateLoading(state, model) {
        state.uiUpdateLoading = model;
    }
};

export default mutations;