import actions from "./actions";
import mutations from "./mutations";
Vue.use(Vuex);

export default config =>
	new Vuex.Store({
		state: {
			data: null,
			selectedMenuId: config.menuId,
			isLevelDown: false,
			isToggleStackNav: false,
			isTabHidden: false,
			lngVersions: config.lngVersions,
			currSubListLevel: 0,
			activeItem: null,
			fallbackError: config.fallbackError,
			resultsUrl: config.resultsUrl,
			isOpen: false,
			isActive: false,
			isRenderSublist: false,
			jsonUrl: config.url,
			uiUpdateLoading: false
		},
		actions,
		mutations
	});
