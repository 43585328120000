var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "ml-stack-nav", class: [_vm.isOpen(), _vm.isActive()] },
    [
      _vm.$store.state.data
        ? _c("h3", {
            staticClass: "ml-stack-nav__headline",
            domProps: { textContent: _vm._s(_vm.getHeadline()) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("button", {
        staticClass: "ml-stack-nav__close",
        domProps: { textContent: _vm._s(_vm.closeButtonText()) },
        on: {
          click: function($event) {
            return _vm.closeNav()
          }
        }
      }),
      _vm._v(" "),
      _vm.$store.state.data
        ? _c("NavList", {
            attrs: { list: _vm.$store.state.data.navigationLists }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.$store.state.data && !_vm.$store.state.fallbackError
        ? _c("NavLoading")
        : _vm._e(),
      _vm._v(" "),
      !_vm.$store.state.data && _vm.$store.state.fallbackError
        ? _c("LogError")
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }