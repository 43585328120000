var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "ml-stack-nav__menu ml-stack-nav-p" },
    [
      _c("li", { staticClass: "fragment-li" }, [
        _vm.item.content.sublist.content.headline
          ? _c("h4", {
              staticClass: "ml-stack-nav__sub-headline",
              class: _vm.item.content.sublist.settings.navigationClasses,
              domProps: {
                textContent: _vm._s(_vm.item.content.sublist.content.headline)
              }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._l(_vm.item.content.sublist.content.items, function(item, index) {
        return _c("NavListItem", {
          key: index,
          attrs: { item: item, index: index }
        })
      }),
      _vm._v(" "),
      _c("li", { staticClass: "fragment-li" }, [
        _vm.lngHeadline()
          ? _c("h4", {
              staticClass: "ml-stack-nav__sub-headline",
              class: _vm.$store.state.lngVersions.settings.navigationClasses,
              domProps: {
                textContent: _vm._s(
                  _vm.$store.state.lngVersions.content.headline
                )
              }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._l(_vm.lngList(), function(lng, index2) {
        return _c("NavLngSwitch", { key: "sw_" + index2, attrs: { lng: lng } })
      }),
      _vm._v(" "),
      _c("li", { staticClass: "ml-stack-nav__item" }, [
        _c("button", {
          staticClass: "ml-stack-nav__back",
          attrs: { type: "button", title: "Go to the previous level" },
          domProps: {
            textContent: _vm._s(_vm.$store.state.data.lngtxt.backLink)
          },
          on: { click: _vm.onBackClick }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "is-aural",
          domProps: {
            textContent: _vm._s(_vm.$store.state.data.lngtxt.backTxt)
          }
        })
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }