var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.cssPostfix(), attrs: { "data-css": "c-filterable-list" } },
    [
      _c(
        "form",
        {
          staticClass: "c-form--filterable-list",
          class: [_vm.isLoading(), _vm.isSuccess()],
          attrs: { "data-css": "c-form" }
        },
        [
          _c("NewsLoader"),
          _vm._v(" "),
          _vm.isCompleted ? _c("Playlist") : _vm._e(),
          _vm._v(" "),
          _vm.$store.getters.getError ? _c("PlaylistError") : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }