var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    _vm._b(
      {
        staticClass: "ml-stack-nav__link",
        class: _vm.item.content.link.settings.linkClasses,
        attrs: { href: _vm.getHref() },
        on: { click: _vm.openNextLevel }
      },
      "a",
      _vm.getTarget(),
      false
    ),
    [
      _c("NavLinkContent", {
        attrs: { item: _vm.item.content.link, scount: _vm.scount }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }