var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "ml-stack-nav__menu" }, [
      _c("li", { staticClass: "ml-stack-nav__item" }, [
        _c("span", { staticClass: "ml-stack-nav__load-init-loader" }, [
          _c("span", {
            staticClass: "ml-stack-nav__load-init-loader-box is-one"
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "ml-stack-nav__load-init-loader-box is-two"
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "ml-stack-nav__load-init-loader-box is-three"
          })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }