var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "error-page__content",
      attrs: { "data-css": "c-error-page" }
    },
    [
      _c("h2", { staticClass: "error-page__headline" }, [
        _vm._v(
          "\n                Die Seite ist aktuell nicht verfügbar.\n            "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "error-page__description" }, [
        _c("p", [
          _vm._v(
            "\n                    Wir arbeiten momentan an unserer Webseite der Ludwig-Maximilians-Universität München und deshalb kannst Du sie gerade nicht erreichen.\n                    Versuche es in einigen Minuten noch einmal.\n                "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._t("default")], 2)
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }