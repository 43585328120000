var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filterable-list__list-item-image" }, [
    _c(
      "picture",
      {
        staticClass: "c-picture--default lazyload",
        attrs: { "data-css": "c-picture" }
      },
      [
        _c("img", {
          staticClass: "picture__image lazyload",
          attrs: { src: _vm.src, alt: _vm.alt, title: _vm.title }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }