var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.lng
    ? _c(
        "li",
        { staticClass: "ml-stack-nav__item" },
        [
          !_vm.lng.content.link.content.dataContent
            ? _c("NavLink", { attrs: { item: _vm.lng, scount: 0 } })
            : _c("div", { attrs: { "data-js-module": "overlay-handler" } }, [
                _c(
                  "a",
                  {
                    staticClass: "ml-stack-nav__link",
                    attrs: {
                      href: "#",
                      "aria-haspopup": "true",
                      "aria-controls": "overlay",
                      "aria-expanded": "false",
                      "data-js-item": "overlay-handler-cta",
                      "data-content": JSON.stringify(
                        _vm.lng.content.link.content.dataContent
                      )
                    }
                  },
                  [
                    _c("NavLinkContent", {
                      attrs: { item: _vm.lng.content.link, scount: 0 }
                    })
                  ],
                  1
                )
              ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }