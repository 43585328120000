var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { staticClass: "filterable-list__list-item" },
    [
      _c("PlaylistItemPicture", {
        attrs: {
          src: _vm.item.posterUrl,
          alt: _vm.item.title,
          title: _vm.item.title
        }
      }),
      _vm._v(" "),
      _c("PlaylistItemContent", { attrs: { item: _vm.item } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }