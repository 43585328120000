var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filterable-list__list-wrapper" }, [
    _c(
      "ul",
      { staticClass: "filterable-list__list" },
      _vm._l(_vm.playList, function(item) {
        return _c("PlaylistItem", { key: item.id, attrs: { item: item } })
      }),
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "filterable-list__load-more-wrapper" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }