var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "lmucast-playlist__list-item-links" }, [
    _c(
      "a",
      {
        staticClass: "lmucast-playlist__list-item-links-link is-icon is-video",
        attrs: { target: "_blank", href: _vm.videoUrl }
      },
      [_vm._v(_vm._s(_vm.$store.state.texts?.links?.videoLink))]
    ),
    _vm._v(" "),
    _vm.$store.state.showDownload
      ? _c(
          "a",
          {
            staticClass:
              "lmucast-playlist__list-item-links-link is-icon is-download",
            attrs: { target: "_blank", href: _vm.videoUrl }
          },
          [_vm._v(_vm._s(_vm.$store.state.texts?.links?.downloadLink))]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$store.state.showAudio
      ? _c(
          "a",
          {
            staticClass:
              "lmucast-playlist__list-item-links-link is-icon is-audio",
            attrs: { target: "_blank", href: _vm.audioUrl }
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$store.state.texts?.links?.audioLink) +
                "\n        "
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }