//import "es6-promise/auto";
import App1 from '../../hamburger-menu/app/src/App.vue';
import App2 from './src/App.vue';
import App3 from "../../mobile-nav-toggler/app/src/App.vue";

// State Management
import store from './src/store';

Vue.component('hamburger-menu-app', App1);
Vue.component('stack-navigation-app', App2);
Vue.component('mobile-navigation-app', App3);

if (__DEV__) {
	Vue.config.debug = true;	
	Vue.config.devtools = true;
} else {
	Vue.config.debug = false;	
}

(function (window) {
	let _init = ({ config = {} }) => {
		var hmStore = store(config);

		const app1 = document.getElementById('hamburgermenu');
		if (app1) {
			console.log('LmuHamburgerMenu Initialised');
			new Vue({
				store: hmStore,
				el: app1,
				appConfig: config
			});
		} else {
			console.error('LmuHamburgerMenu Initialised failed!');
		}
		

		const app2 = document.getElementById('stacknav');
		if (app2) {
			console.log('LmuStackNavigation Initialised');
			new Vue({
				store: hmStore,
				el: app2,
				appConfig: config
			});
		} else {
			console.error('LmuStackNavigation Initialised failed!');
		}

		const app3 = document.getElementById('mobilenav');
		if (app3) {
			console.log('LmuMobilenavigation Initialised');
			new Vue({
				store: hmStore,
				el: app3,
				appConfig: config
				});
		} else {
			console.error('LmuMobileNavigation Initialised failed!');
		}
		
	};

	window.LmuStackNavigation = {
		init: _init
	};
})(window);
