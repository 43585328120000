var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filterable-list__list-item-text-wrapper" },
    [
      _c(
        "em",
        {
          staticClass:
            "filterable-list__list-item-meta lmucast-playlist__list-item-meta"
        },
        [
          _c("span", [
            _vm._v(
              "\n            " +
                _vm._s(_vm.formatPubDate(_vm.item.pubDate)) +
                "\n        "
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "lmucast-playlist__list-item-title" }, [
        _vm._v(_vm._s(_vm.item.title))
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "lmucast-playlist__list-item-subtitle" }, [
        _vm._v(_vm._s(_vm.item.subtitle))
      ]),
      _vm._v(" "),
      _c("PlaylistItemLinks", {
        attrs: {
          "video-url": _vm.item.videoUrl,
          "audio-url": _vm.item.audioUrl
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "lmucast-playlist__list-item-description-wrapper" },
        [
          _c(
            "span",
            { staticClass: "lmucast-playlist__list-item-description-headline" },
            [_vm._v(_vm._s(_vm.$store.state.texts?.authorTitle) + ":")]
          ),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "lmucast-playlist__list-item-description-speaker" },
            [_vm._v(_vm._s(_vm.item.author))]
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "lmucast-playlist__list-item-description-text" },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.item.description) + "\n        "
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }