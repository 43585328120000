var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ul", { staticClass: "ml-stack-nav__menu" }, [
    _c("li", { staticClass: "ml-stack-nav__item" }, [
      _c("div", { staticClass: "ml-stack-nav__error" }, [
        _c("div", { staticClass: "grid__container" }, [
          _c("div", { staticClass: "grid__row" }, [
            _c("div", { staticClass: "ml-stack-nav__error content" }, [
              _c(
                "h2",
                { staticClass: "ml-stack-nav__error content headline" },
                [
                  _c("span", {
                    attrs: { "data-nosnippet": "true" },
                    domProps: {
                      textContent: _vm._s(_vm.$store.state.fallbackError.title)
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "ml-stack-nav__error content description",
                attrs: { "data-nosnippet": "true" },
                domProps: {
                  textContent: _vm._s(_vm.$store.state.fallbackError.text)
                }
              })
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }